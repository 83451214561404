import { graphql } from 'gatsby';
import React from 'react';
import { ContactSection } from '../components/ContactSection';
import { Hero, HeroImageFragment, SrcMap } from '../components/Hero';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';

interface Props {
  data: {
    page: {
      heroImage: {
        backgroundImage: HeroImageFragment;
      };
    };
  };
}

export default (props: Props) => {
  const {
    heroImage: { backgroundImage },
  } = props.data.page;
  const imageMap: SrcMap = {
    sm: backgroundImage.sm.src,
    md: backgroundImage.md.src,
    lg: backgroundImage.lg.src,
    xl: backgroundImage.xl.src,
  };
  return (
    <Layout title="Contact Us">
      <Hero imageSrc={imageMap} title="Contact Us" />
      <Section
        title="Want to talk?"
        subTitle="Contact us today to discuss your specific needs and objectives"
      >
        <ContactSection />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ContactUsQuery {
    page: contentfulLayout(slug: { eq: "/contact-us" }) {
      heroImage {
        backgroundImage {
          ...HeroImage
        }
      }
    }
  }
`;
